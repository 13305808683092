import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { Button, CardActions } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TodayBreakFast from '../Home/TodayBreakFast';
import TodayDinner from '../Home/TodayDinner';
import TodayLunch from '../Home/TodayLunch';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Plate from '../Plates/Plate';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Product = () => {
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  let params = useParams();
  const product = useSelector((state) => state.product.product);

  useEffect(() => {
    document.title = product.name;
  }, [params]);

  return (
    <div style={{marginTop: '80px'}}>
      <Container sx={{boxShadow: 2, padding: 1}}>
      <Grid container spacing={0}>
          <Grid  item md={6}>
          <Card sx={{marginX: '5px'}} elevation={0}>
                <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                        image={product.product_image}
                        alt="Live from space album cover"
                    />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
          <Card sx={{ minWidth: 275 }} elevation={0}>
          <CardContent>
              <Box sx={{display: 'flex',
        alignItems: 'center', justifyContent: 'center', }}>
                  <Stack mt={-1} sx={{ justifyContent: 'center', alignItems: 'center' }}  spacing={1}>
                    <Rating name="half-rating" size="large" defaultValue={4.5} precision={0.5} readOnly />
                  </Stack>
                  <Typography ml={2} sx={{ fontWeight: 'bold', fontSize: 24, marginTop: '4px' }} gutterBottom variant="subtitle2" component="span">
                    4.5
                </Typography>
              </Box>
                
                <Typography mt={1} sx={{ fontWeight: 'bold', fontSize: 24  }} gutterBottom variant="subtitle2" component="div">
                    {product.name}
                </Typography>
                {/* <Typography my={0} sx={{textAlign: 'left'}}  variant="body2" color="text.secondary">
                {product.short_description}
                </Typography> */}

                {/* <Typography mt={2} sx={{ fontWeight: 'bold', fontSize: 16, textAlign: 'left'  }} gutterBottom variant="subtitle2" component="div">
                    Description
                </Typography> */}
                <Typography my={0} sx={{textAlign: 'left'}} variant="body2" color="text.secondary">
                {product.description}
                </Typography>                
                
                
                </CardContent>
                <CardActions sx={{justifyContent: 'space-between', paddingX: '15px'}}>  
                {product.meal_type !== 'Services' &&
                <Typography mb={0} color="secondary" mt={0} sx={{textAlign: 'left', fontWeight: 'bold', fontSize: 24 }} gutterBottom variant="button" component="div">
                Price: {formatPrice(product.unit_price)}
                </Typography>
                }
                

                <Plate product={product}  />
                {/* <Button sx={{ textTransform: 'capitalize' }} variant="contained" startIcon={<AddCircleOutlineIcon />}>
                    Add to Plate
                </Button> */}
                
                </CardActions> 
          </Card>
          </Grid>
        </Grid>
        
        </Container>
        <Divider/>
        <TodayBreakFast/>
        <TodayLunch/>
        <TodayDinner/>
    </div>
  )
}

export default Product
