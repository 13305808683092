import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import AddchartIcon from '@mui/icons-material/Addchart';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';

export default function MobileMenu() {
  return (
    <Stack mt={2} sx={{display: {md: 'none'}}} direction="row" spacing={4} justifyContent="center" alignItems="center">
      <Box component={RouterLink}  to='/category/Sports' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56, marginBottom: '5px'}}>
        <SportsSoccerIcon sx={{fontSize: '48px'}}/>
      </Avatar>  
      <Typography variant="caption">Sports</Typography>   
      </Box>

      <Box component={RouterLink} to='/category/Economics' sx={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56, marginBottom: '5px'}}>
        <AddchartIcon sx={{fontSize: '48px'}}/>
      </Avatar>
      <Typography variant="caption">Economics</Typography> 
      </Box>

      <Box component={RouterLink} to='/category/General Knowledge' sx={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56, marginBottom: '5px'}}>
        <SchoolIcon sx={{fontSize: '48px'}}/>
      </Avatar>
      <Typography variant="caption">General Knowledge</Typography> 
      </Box>

      <Box component={RouterLink} to='/category/Tech' sx={{ borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56, marginBottom: '5px'}}>
        <ComputerIcon sx={{fontSize: '48px'}}/>
      </Avatar>
      <Typography variant="caption">Tech</Typography> 
      </Box>      
    </Stack>
  );
}