import React, {useState, useEffect, Fragment} from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from "react-query";
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import apiClient from "../../request/http-common";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import { CardActions, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Fireworks } from 'fireworks-js'


const TicketPlayground = (props) => {

  


  const {product, ticket} = props; 

  // const coins = useSelector((state) => state.coins.coins);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  // const addresses = useSelector((state) => state.coins.addresses);

  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const subBal = useSelector((state) => state.wallet.subscriptionBalance);
  const refBal = useSelector((state) => state.wallet.referralBalance);
  // const mainBal = useSelector((state) => state.wallet.withdrawableBalance);


  const [error, setErros] = useState('');
  const [amount, setAmount] =useState(0);
  const [answerName, setanswerName] =useState('');
  const [answerName2, setanswerName2] =useState('');
  const [answerName3, setanswerName3] =useState('');
  const [answerName4, setanswerName4] =useState('');

  const [amountWon, setAmountWon] = useState(0);
  const [ticketStatus, setTicketStatus] = useState(product.status);
  

  
  const [address, setAddress] =useState('');
  const [source, setSource] =useState('');
  
  const [barstate, setBarState] = useState({
      top: false,
      bottom: false,
      bottom: false,
      right: false,
    });


  const togglebottomBar = () => {
      // const event = new Event("toggleWithdrawalBar");
      // window.dispatchEvent(event);

      setBarState({ ...barstate, ['bottom']: true });
    }

    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setBarState({ ...barstate, [anchor]: open });
    };


    const { isLoading: isWithdrawing, mutate: postWithdrawal } = useMutation(
      async () => {
        return await apiClient.post(`/api/credit-earning`, {
          amount: amountWon,
          ticket_id: ticket.id,
          product_id: product.id,
          status: ticketStatus, 
        });
      },
      {
        onSuccess: (res) => {
            if (res.data.status === 'success') {
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);

              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }            
        },
        onError: (err) => {
              if (err.response?.data.length) {
                  let myerror = err.response?.data || err;         
              setErros(myerror.errors) 

              
              }else{
                  let errorMessage = err.response?.data.message || err ;
                  localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
              }
              

              
        },
      }
      
    );

    const [answerOne, setAnswerOne] = useState(null);
    const [answerTwo, setAnswerTwo] = useState(null);
    const [answerThree, setAnswerThree] = useState(null);
    const [playfireworks, setPlayFireworks] = useState(false);
   

    const submitAnswer = (answer_1, answer_2, answer_3) => {

      if (String(product.answer_1).valueOf() === String(answer_1).valueOf()&&
      String(product.answer_2).valueOf() === String(answer_2).valueOf()&&
      String(product.answer_3).valueOf() === String(answer_3).valueOf()

      
       ) {
        setPlayFireworks(true);
        
        localStorage.setItem('notification', JSON.stringify({message : `Congratulations, You Won`, 'type': 'success' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);

              setTimeout(() => {
                const container = document.querySelector('.fireworks')
                const fireworks = new Fireworks(container, { /* options */ })
                fireworks.start();
              }, 1000);

              setTicketStatus('won');
              setAmountWon(parseFloat(product.unit_price) + parseFloat(product.unit_profit))
              setTimeout(() => {
                postWithdrawal();
              }, 1000);

              
      }else{
        localStorage.setItem('notification', JSON.stringify({message : `You Failed, Try Again`, 'type': 'error' }));
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                  setTicketStatus('failed');
                  setTimeout(() => {
                    postWithdrawal();
                  }, 1000);
      }
    }

    useEffect(() => {
   
      if (playfireworks) {
        setTimeout(() => {
          const container = document.querySelector('.fireworks')
          const fireworks = new Fireworks(container, { /* options */ })
          fireworks.start();
        }, 2000);
        
      }
      
    }, [playfireworks]);

  return (
    <div>
      
      {/* <Button onClick={()=>togglebottomBar()} variant="contained" color='secondary' startIcon={<MoneyOffIcon />}>
            start
        </Button> */}

        <Button  
          disabled={(product.status ==='won' || product.status ==='failed') ?true : false }
            sx={{ textTransform: 'capitalize' }} onClick={()=>{togglebottomBar(); setPlayFireworks(false)}} variant="contained" startIcon={<PlayArrowIcon />}>
            
            {(product.status ==='won' || product.status ==='failed') ? product.status : 'Start' }
        </Button>

      <SwipeableDrawer
      
      anchor={`bottom`}
      open={barstate[`bottom`]}
      onClose={toggleDrawer(`bottom`, false)}
      onOpen={toggleDrawer(`bottom`, true)}> 

      <Card sx={{ borderRadius: '9px', height: '100%'}}>
        {!playfireworks &&
        <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
        Kindly answer the provided questions to win {formatPrice(product.unit_profit)}
        </Typography>
        }
      

      {playfireworks ? 
      <div className='fireworks' style={{height: '400px'}}>
        
        <div className="congrats" style={{top: '20%', left: '25%', position: 'absolute'}}>
          YOU WON!<br/>
          {formatPrice(parseFloat(product.unit_profit) + parseFloat(product.unit_price))}

          </div>

      </div>
      :

      <CardContent>

      
            

        {product.question_1 !== null &&

        <Fragment>
          <Box mb={2} mt={4} justifyContent="center">
            <Typography sx={{textDecoration: 'underline'}} variant="subtitle1">
             (1) {product.question_1}
            </Typography>
          </Box>

        <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
        <InputLabel htmlFor="Answer">Select Answer</InputLabel>
        {(error !== '' && error.answerName) ?
            <Select
                error
                onChange={(e)=>setAnswerOne(e.target.value)}
                labelId="Answer"
                id="Answer"
                name="Answer"
                label={`Select Answer`}
                helperText={error.answerName}
                >
                <MenuItem selected value=''>
                    Select Answer
                </MenuItem> 
                {product.options_1.split(',').map((coin, index) =>(
                    <MenuItem key={index} value={coin}>{coin}</MenuItem>
                ))}             
                
            </Select>
        :
            <Select
                onChange={(e)=>setAnswerOne(e.target.value)}
                labelId="Answer"
                name="Answer"
                id="Answer"
                label={`Select Answer`}
                >
                <MenuItem selected value="">
                    Select Answer
                </MenuItem>     
                {product.options_1.split(',').map((coin, index) =>(
                    <MenuItem key={index} value={coin}>{coin}</MenuItem>
                ))}
            </Select>
        }
        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
        {error !== '' && error.answerName}
        </Typography>
    </FormControl>
    </Fragment>
        }


{product.question_2 !== null &&

<Fragment>
  <Box mb={2} mt={4} justifyContent="center">
    <Typography sx={{textDecoration: 'underline'}} variant="subtitle1">
     (2) {product.question_2}
    </Typography>
  </Box>

<FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
<InputLabel htmlFor="answer_2">Select Answer</InputLabel>
{(error !== '' && error.answer_2) ?
    <Select
        error
        onChange={(e)=>setAnswerTwo(e.target.value)}
        labelId="answer_2"
        id="answer_2"
        name="answer_2"
        label={`Select Answer`}
        helperText={error.answer_2}
        >
        <MenuItem selected value=''>
            Select Answer
        </MenuItem> 
        {product.options_2.split(',').map((coin, index) =>(
            <MenuItem key={index} value={coin}>{coin}</MenuItem>
        ))}             
        
    </Select>
:
    <Select
        onChange={(e)=>setAnswerTwo(e.target.value)}
        labelId="answer_2"
        name="answer_2"
        id="answer_2"
        label={`Select Answer`}
        >
        <MenuItem selected value="">
            Select Answer
        </MenuItem>              
        {product.options_2.split(',').map((coin, index) =>(
            <MenuItem key={index} value={coin}>{coin}</MenuItem>
        ))}
    </Select>
}
<Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
{error !== '' && error.answer_2}
</Typography>
</FormControl>
</Fragment>
}


{product.question_3 !== null &&

<Fragment>
  <Box mb={2} mt={4} justifyContent="center">
    <Typography sx={{textDecoration: 'underline'}} variant="subtitle1">
     (3) {product.question_3}
    </Typography>
  </Box>

<FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
<InputLabel htmlFor="answer_3">Select Answer</InputLabel>
{(error !== '' && error.answer_3) ?
    <Select
        error
        onChange={(e)=>setAnswerThree(e.target.value)}
        labelId="answer_3"
        id="answer_3"
        name="answer_3"
        label={`Select Answer`}
        helperText={error.answer_3}
        >
        <MenuItem selected value=''>
            Select Answer
        </MenuItem> 
        {product.options_3.split(',').map(coin =>(
            <MenuItem key={coin} value={coin}>{coin}</MenuItem>
        ))}             
        
    </Select>
:
    <Select
        onChange={(e)=>setAnswerThree(e.target.value)}
        labelId="answer_3"
        name="answer_3"
        id="answer_3"
        label={`Select Answer`}
        >
        <MenuItem selected value="">
            Select Answer
        </MenuItem>              
        {product.options_3.split(',').map(coin =>(
            <MenuItem key={coin} value={coin}>{coin}</MenuItem>
        ))}
    </Select>
}
<Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
{error !== '' && error.answer_3}
</Typography>
</FormControl>
</Fragment>
}





        </CardContent>
}
{!playfireworks &&
  <CardActions>
  
  <Button onClick={()=>submitAnswer(answerOne, answerTwo, answerThree)} fullWidth={true} size="large" color="primary" variant="contained">{isWithdrawing ? 'Processing....': 'Submit Answer'}</Button>

  </CardActions>
  }

        </Card>
    </SwipeableDrawer>
    </div>
  )
}

export default TicketPlayground
