import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletButton from '../Wallet/WalletButton';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { CardActions } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReactApexChart from 'react-apexcharts';
import Button from '@mui/material/Button';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function AdminAnalysis() {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    
    const todaysProfit = useSelector((state) => state.unitsAnalysis.todaysProfit);
    const amountSoldToday = useSelector((state) => state.unitsAnalysis.amountSoldToday);
    const todaysFundings = useSelector((state) => state.unitsAnalysis.todaysFundings);
    const todaysExpenses = useSelector((state) => state.unitsAnalysis.todaysExpenses);
    const totalSales = useSelector((state) => state.unitsAnalysis.totalSales);
    const totalFundings = useSelector((state) => state.unitsAnalysis.totalFundings);
    const totalUsers = useSelector((state) => state.unitsAnalysis.totalUsers);
    const totalExpenses = useSelector((state) => state.unitsAnalysis.totalExpenses);

    const salesAnalysis = useSelector((state) => state.chartsAnalysis.salesAnalysis);
    const fundingsAnalysis = useSelector((state) => state.chartsAnalysis.fundingsAnalysis);
    const profitAnalysis = useSelector((state) => state.chartsAnalysis.profitAnalysis);
    const expensesAnalysis = useSelector((state) => state.chartsAnalysis.expensesAnalysis);

    const product_qty_sold = useSelector((state) => state.unitsAnalysis.product_qty_sold);
    const product_table_name = useSelector((state) => state.unitsAnalysis.product_table_name);
    
    function getRandomColor() {
      let colorArray = [];

      product_qty_sold.forEach(element => {
        var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
        colorArray.push(color);
      }
      
      });
      return colorArray;
    }
    
    let salesData = [];
    let fundingsData = [];
    let profitsData = [];
    let expenseData = [];

    salesAnalysis.forEach(element => {
        salesData.push(element.amount);
    });

    fundingsAnalysis.forEach(element => {
        fundingsData.push(element.amount);
    });

    profitAnalysis.forEach(element => {
        profitsData.push(element.amount);
    });

    expensesAnalysis.forEach(element => {
        expenseData.push(element.amount);
    });


    const series =  [{
        name: 'Total Sales',
        data: salesData
      },
      {
        name: 'Total Fundings',
        data: fundingsData
      },
      {
        name: 'Total Profits',
        data: profitsData
      }
      ,
      {
        name: 'Total Expenses',
        data: expenseData
      }
    ];

    const options = {
        chart: {
            foreColor: '#939c92',
          type: 'bar',
          height: 350,
        },
        
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        tooltip: {
            theme: 'dark'
          },
        xaxis: {
            theme: 'dark',
          categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: 'NGN (Nigerian Naira)'
          }
        },
        fill: {
          opacity: 1
        },
        
      };

      const salesSeries = [{
          data: product_qty_sold
        }]


      const salesOptions = {
        chart: {
          type: 'bar',
          foreColor: '#939c92',
          height: 1500
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: getRandomColor,
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: product_table_name,
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
            text: 'Products Sold',
            align: 'center',
            floating: true
        },
        subtitle: {
            text: 'Quantity of products sold',
            align: 'center',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      };


      const [open, setOpen] = React.useState(false);
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

    const [expenseAmount, setExpenseAmount] = useState(0);
    const [reason, setReason] = useState('');

      const { isLoading: isSpending, mutate: postExpenses } = useMutation(
        async () => {
          return await apiClient.post(`/api/store-expenses`, {
            amount: expenseAmount,
            reason: reason,
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                
              }            
          },
          onError: (err) => {
                let errorMessage = err.response?.data || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
          },
        }
      );

   return (
    <div style={{marginTop: '70px'}}>
      

    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>        
            <Grid item xs={12} md={3}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#0be930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#0be930'}} primary={formatPrice(todaysProfit)} secondary={`Today's Profit`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>  

            <Grid item xs={12} md={3}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#fa8d0d'}}/>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fa8d0d'}} primary={formatPrice(amountSoldToday)} secondary={`Today's Sales`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                           <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#da0930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#da0930'}} primary={formatPrice(todaysExpenses)} secondary={`Today's Expense`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>

                </Card>
            </Grid>

            <Grid item xs={12} md={3}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06b13e'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06b13e'}} primary={formatPrice(todaysFundings)} secondary={`Today's Funding`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06b13e'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06b13e'}} primary={formatPrice(totalSales)} secondary={`Total Sales Made`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06afad'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06afad'}} primary={formatPrice(totalFundings)} secondary={`Total Amount Funded`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                           <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#da0930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#da0930'}} primary={formatPrice(totalExpenses)} secondary={`Total Amount Spent`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>

                    <CardActions sx={{justifyContent: 'center'}}>
                    <Button onClick={handleClickOpen} variant="outlined" size='large' color="primary">
                        {isSpending? 'spending ...' : 'Make Expenses'}
                    </Button>
                    </CardActions>

                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PeopleAltIcon />
                                </ListItemIcon>
                                <ListItemText primary={totalUsers} secondary={`Total Users`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid> 

            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <ReactApexChart options={salesOptions} series={salesSeries} type="bar" height={1500} />
                    </CardContent>
                </Card>
            </Grid> 

            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <ReactApexChart options={options}  series={series} type="bar" height={350} />
                    </CardContent>
                </Card>
            </Grid> 
    </Grid>


    
      
    <Dialog
        sx={{zIndex: 40000}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"MAKE EXPENSES"}</DialogTitle>

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
            Kindly enter any amount to spend
          </DialogContentText>

          <Box px={1}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount To Spend</InputLabel>
              <OutlinedInput
                onChange={(e)=>setExpenseAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount To Spend"
              />
            </FormControl>


            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="reason">Reason</InputLabel>
              <OutlinedInput
                onChange={(e)=>setReason(e.target.value)}
                id="reason"
                type={'text'}
                label="Reason"
              />
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
        <Button onClick={postExpenses}  color='success'>{isSpending? 'spending...': 'submit expenses'}
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}