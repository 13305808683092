import React, {useEffect, useState} from 'react'
// import 'react-wheel-of-prizes/dist/index.css'
import { Card, CardContent, CardActions } from '@mui/material'
import WheelComponent from 'react-wheel-of-prizes'
import { Padding } from '@mui/icons-material'
import { Fireworks } from 'fireworks-js'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import apiClient from "../../request/http-common";
import { useMutation } from "react-query";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button } from '@mui/material';

const PlaySpinGame = (props) => {
    const {product, ticket} = props; 
    const [amountWon, setAmountWon] = useState(null);
    const [ticketStatus, setTicketStatus] = useState(product.status);

    const [error, setErros] = useState('');
    const segmentVal = (min, max) => parseInt(Math.random() * (max - min) + min);
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard'}).format(amount);

    const segments = [
      getAmount(segmentVal(1000, 5000)),
      getAmount(segmentVal(0, 0)),
      getAmount(segmentVal(8000, 12000)),
      getAmount(segmentVal(15000, 20000)),
      getAmount(segmentVal(25000, 30000)),
      getAmount(segmentVal(30000, 40000)),
      getAmount(segmentVal(4000, 7000)),
      getAmount(segmentVal(50000, 100000)),
      ]
      const segColors = [
        '#EE4040',
        '#F0CF50',
        '#815CD1',
        '#3DA5E0',
        '#34A24F',
        '#F9AA1F',
        '#EC3F3F',
        '#FF9000'
      ]
      const onFinished = (winner) => {
        setAmountWon(winner);
        if (Number(winner.replace(/[^0-9.-]+/g,"")) > 0) {
            setTicketStatus('won')
        }else{
            setTicketStatus('failed')
        }
        setTimeout(() => {
            postWithdrawal()
        }, 1000);
      }

      const [barstate, setBarState] = useState({
        top: false,
        bottom: false,
        bottom: false,
        right: false,
      });
  
  
    const togglebottomBar = () => {
      setBarState({ ...barstate, ['bottom']: true });
        // const event = new Event("toggleWithdrawalBar");
        // window.dispatchEvent(event);
      }
  
      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setBarState({ ...barstate, [anchor]: open });
      };



      const { isLoading: isWithdrawing, mutate: postWithdrawal } = useMutation(
        async () => {
          return await apiClient.post(`/api/credit-earning`, {
            amount: Number(amountWon.replace(/[^0-9.-]+/g,"")),
            ticket_id: ticket.id,
            product_id: product.id,
            status: ticketStatus, 
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
  
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }            
          },
          onError: (err) => {
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 
  
                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }
                
  
                
          },
        }
        
      );
        // const [mycanvas, setMycanvas] = useState(document.getElementById('canvas'))
      useEffect(() => {
        
        if (amountWon != null) {
          const container = document.querySelector('.fireworks')
          const fireworks = new Fireworks(container, { /* options */ })
          fireworks.start();
        }

        // window.addEventListener(
        //     "toggleWithdrawalBar",
        //     (e) => {
        //      setBarState({ ...barstate, ['bottom']: true });
        //     },
        //     false
        //   );
            
        }, [amountWon, props]);

      return (
    <div>
        <Button  
          disabled={(product.status === 'won' || product.status === 'failed') ?true : false }
            sx={{ textTransform: 'capitalize' }} onClick={()=>{togglebottomBar();

                setTimeout(() => {
                    var mycanvas = document.getElementById('canvas');
                    if (mycanvas !== null) {
                        mycanvas.style = 'width: 100%; font-size: 38px; height: 100%; transform: translate(70px, 75px);';
                    }
                }, 500);
            }} variant="contained" startIcon={<PlayArrowIcon />}>
            
            {(product.status ==='won' || product.status ==='failed') ? product.status : 'Play' }
        </Button>

        <SwipeableDrawer      
            anchor={`bottom`}
            open={barstate[`bottom`]}
            onClose={toggleDrawer(`bottom`, false)}
            onOpen={toggleDrawer(`bottom`, true)}> 

        <Card>

        <WheelComponent
                    segments={segments}
                    segColors={segColors}
                    winningSegment='NA'
                    onFinished={(winner) => onFinished(winner)}
                    primaryColor='green'
                    contrastColor='white'
                    buttonText='Spin'
                    isOnlyOnce={false}
                    size={290}
                    upDuration={500}
                    downDuration={1000}
                    fontFamily='Arial'
                    />
            <CardActions>
              <div className='fireworks' style={{height: '200px'}}>   
                {amountWon != null ?
                
                <div className="congrats" style={{position: 'absolute', top: '20%', left: '25%', right: '25%'}}>
                YOU WON!<br/>
                {amountWon}
                </div>  
                
                :

                <Box mx={2} mt={4}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="info">Win Amazing Prizes By Spinning The Wheel Above</Alert>
                </Stack>
                </Box>
            
                }
              </div>
            </CardActions>
      </Card>
       </SwipeableDrawer>
    </div>
  )
}

export default PlaySpinGame
