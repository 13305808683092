import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import CalculateIcon from '@mui/icons-material/Calculate';
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import ChatIcon from '@mui/icons-material/Chat';
export default function Footer() {
  const items = useSelector((state) => state.plate.items);
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/category/Sports" label="Sports" icon={<SportsVolleyballIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/Mathematics" label="Mathematics" icon={<CalculateIcon />} />
          <BottomNavigationAction  href='/chat.html' label="Chat" icon={<ChatIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/General Knowledge" label="General" icon={<SchoolIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/Science" label="Science" icon={
          <ScienceIcon />} />
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
