import React, {Fragment} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WithdrawalButton from './WithdrawalButton';
import WalletButton from '../Wallet/WalletButton';

import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';

const Withdrawals = () => {
    const user = useSelector((state) => state.user.user);
    const withdrawals = useSelector((state) => state.products.withdrawals);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  return (
    <div>

      <WalletSlide/>
      <Card >
        <CardActions sx={{justifyContent: 'space-between'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card>

<Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        WITHDRAWAL HISTORY
</Typography>

{withdrawals.length > 0 &&
<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

{withdrawals.map(withdrawal => (
   <Fragment key={withdrawal.id}>
  <ListItem alignItems="flex-start">
  <ListItemAvatar>
    
    {withdrawal.status === 'pending' &&
      <Avatar  sx={{ bgcolor: '#f7a21d' }}>
       <PendingIcon/>
      </Avatar>
    }
       
    {withdrawal.status === 'approved' &&
     <Avatar  sx={{ bgcolor: '#37dc8a' }}>
       <CheckCircleIcon/>
      </Avatar>
     }

    {withdrawal.status === 'declined' &&
    <Avatar  sx={{ bgcolor: '#dd0a22' }}>
      <DangerousIcon/>
    </Avatar>
     
     }
    
     
  </ListItemAvatar>
  <ListItemText
    primary={withdrawal.coin_name}
    secondary={
      <React.Fragment>
        <Typography
          sx={{ display: 'inline' }}
          component="span"
          variant="body2"
          color="text.primary"
        >
          {withdrawal.address}
        </Typography>
      </React.Fragment>
    }
  />
   

    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
        <Typography mt={1} edge="end">
        {formatPrice(withdrawal.amount)}
        </Typography>
        <Typography color={withdrawal.status === 'pending'? 'secondary': 'success'}  edge="end">
        {withdrawal.status}
        </Typography>
    </Box>

  
</ListItem>
<Divider variant="inset" component="li" />
</Fragment>
))}
</List>
}
    </div>
  )
}

export default Withdrawals
