import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TransactionList from './TransactionList';

import WalletSlide from './WalletSlide';
import WalletButton from './WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';

const Wallet = () => {
  
  return (
    <div>
        <WalletSlide/>
        <Container>
          <Card elevation={1}  mx={0} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography my={0} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
              Click the button below to fund your account
              </Typography>
              </CardContent>  
              <CardActions sx={{justifyContent: 'space-between'}}>
              {/* button stays here */}
              <WalletButton/>
              <WithdrawalButton/>
              </CardActions>
            </Box>
          </Card>
        <TransactionList/>
        </Container>
    </div>
  )
}

export default Wallet
