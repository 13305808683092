import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setReadMessages } from './messageSlice';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import YouTube from 'react-youtube';
import { CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


const ShowMessage = () => {
  const opts = {
    playerVars: {
      height: '180px',
      width: '320px',
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const [videoOpts, setVideoOpts] = useState(opts);

  const onready = (e) => e.target.playVideo();
  const onbocked = (e) => e.target.playVideo();

    const message = useSelector((state) => state.messages.messages);
    const readMessage = useSelector((state) => state.messages.readMessages);

    const dispatch = useDispatch();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
      };

        const [open, setOpen] = useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        useEffect(() => {
            setTimeout(() => {                
                if ((message !== null) && (message !== '') && (parseInt(message.id) !== parseInt(readMessage))) {
                    handleOpen()
                }
            }, 2000);
        });

  return (
    <div>
    {message && 
      <Modal
        open={open}
        onClose={()=>{
            handleClose();
            dispatch(setReadMessages(message));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style} px={2} mx={0}>
            <CardContent>
            {message.media_type === 'image' &&
              <CardMedia
              component="img"
              image={message.link}
              alt={message.title}
              />
          }

          {message.media_type === 'video' &&
              <Box>
              <YouTube iframeClassName="youtubeiframe" onAutoplayBlocked={onbocked} videoId={message.link} opts={opts} onReady={onready} />
              </Box>
            }
          <Typography color="info" sx={{fontWeight: 'bolder', textAlign: 'center'}} id="modal-modal-title" variant="h6" component="h2">
          {(message !== null && message !== '') && message.title}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {(message !== null && message !== '') && message.message}
          </Typography>

          
          </CardContent>

          {message.external_link !== null &&
          <CardActions>
            <Button to='https://kitchen.menubarkitchen.com' component={RouterLink} fullWidth={true} size="large" color="primary" variant="contained"> 
            {message.link_text}
            </Button>

          </CardActions>
          }
          
        </Card>
      </Modal>
      }
    </div>
  )
}

export default ShowMessage
