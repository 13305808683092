import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { addItem } from './plateSlice';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Timer from './Timer';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Badge from '@mui/material/Badge';
import BookIcon from '@mui/icons-material/Book';


import { CardActions, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Plate = (props) => {
    const {product, caption} = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
  };

  const [error, setErros] = useState('');

  const togglebottomBar = () => {
    setBarState({ ...barstate, ['bottom']: true });
  }
  const [barstate, setBarState] = useState({
    top: false,
    bottom: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setBarState({ ...barstate, [anchor]: open });
  };

  // useEffect(() => {
  //   window.addEventListener(
  //     "toggleWithdrawalBar",
  //     (e) => {
  //      setBarState({ ...barstate, ['bottom']: true });
  //     },
  //     false
  //   );
    
  // }, []);

  return (
    <>
    {parseInt(product.qty) < 1 ?
      <Button disabled fullWidth={true} sx={{ textTransform: 'capitalize' }} color='error' variant="outlined" startIcon={(parseInt(product.qty)  === 0) ? <NotInterestedIcon /> :  <AccessAlarmIcon/>}>
        {(parseInt(product.qty)  === 0) ? 'Finished' :  <Timer time={product.timer !== null? product.timer : 0 }/>}
      </Button>
     :

     product.meal_type === 'Services'? 
      <Button onClick={()=>togglebottomBar()} fullWidth={true} sx={{ textTransform: 'capitalize' }} variant="outlined" startIcon={<BookIcon />}>
       {caption? caption : 'Get Ticket'} 
      </Button>
     :
     <Badge badgeContent={parseInt(product.qty)} color="secondary">
     <Button fullWidth={true} onClick={() => dispatch(addItem(product))} sx={{ textTransform: 'capitalize' }} variant="outlined" startIcon={<AddCircleOutlineIcon />}>
      {caption? caption : 'Get Ticket'} 
     </Button>
   </Badge>
     }
    
        
      <SwipeableDrawer
      
      anchor={`bottom`}
      open={barstate[`bottom`]}
      onClose={toggleDrawer(`bottom`, false)}
      onOpen={toggleDrawer(`bottom`, true)}> 

      <Card sx={{ borderRadius: '9px', height: '100%'}}>

      <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
      Kindly Fill The Form Below To Book Service
      </Typography>

          <CardContent>

          <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
                            <InputLabel htmlFor="Full Name">Full Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            
                            id="name"
                            name="name"
                            type={'text'}
                            label="Full Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            
                            id="name"
                            name="name"
                            type={'text'}
                            label="Full Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
                            <InputLabel htmlFor="phone">Phone</InputLabel>
                            {(error !== '' && error.phone) ? 
                            <OutlinedInput
                            error
                            
                            id="phone"
                            name="phone"
                            type={'tel'}
                            label="Phone"
                            helperText={error.phone}
                            />
                            :
                            <OutlinedInput
                            
                            id="phone"
                            name="phone"
                            type={'tel'}
                            label="Phone"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phone}
                            </Typography>
                        </FormControl>

                        <FormControl  fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
                            <InputLabel htmlFor="address">Address</InputLabel>
                            {(error !== '' && error.address) ?
                            <OutlinedInput
                            error
                            helperText={error.address}
                            
                            minRows={2} multiline
                            id="address"
                            name="address"
                            type={'text'}
                            label="Address"
                            />
                            :
                            <OutlinedInput
                            
                            minRows={2} multiline
                            id="address"
                            name="address"
                            type={'text'}
                            label="Address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                        </FormControl>


          <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
              <InputLabel htmlFor="description">Describe Issue</InputLabel>
              {(error !== '' && error.description) ?

              <OutlinedInput
              error
              id="description"
              minRows={4} multiline
              type={'text'}
              name="description"
              label="Describe Issue"
              helperText={error.description}
              />
              :
              <OutlinedInput
              id="description"
              minRows={4} multiline
              type={'text'}
              name="description"
              label="Describe Issue"
              />
              }
              <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              {error !== '' && error.description}
              </Typography>
          </FormControl>
        </CardContent>

          <CardActions>
          <Button fullWidth={true} onClick={()=>setBarState({ ...barstate, ['bottom']: false })} size="large" color="error" variant="contained">{'CANCEL'}</Button>

          <Button fullWidth={true} size="large" color="primary" variant="contained">{'REQUEST SERVICE NOW'}</Button>
          </CardActions>

        </Card>
    </SwipeableDrawer>
    </>
  )
}

export default Plate
