import React from 'react'

const ChatBox = () => {
  return (
    <div>
      <script id="cid0020000389786428342" data-cfasync="false" async src="//st.chatango.com/js/gz/emb.js" style="width: 200px;height: 300px;"></script>
    </div>
  )
}

export default ChatBox
